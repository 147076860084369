.errorCard {
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #282c34;
}

.errorCard h1 {
    color: #f00;
    font-size: 30px;
    margin-bottom: 20px;
}