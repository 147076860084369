.App{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
}

.card {
    width: 75%;
    background-color: #2D3250;
    min-height: 33vh;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

input {
    width: 50%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #c6c8cb;
    border-radius: 4px;
    box-sizing: border-box;
}

.inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card button{
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    margin-top: 20%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

#alertText {
    position: relative;
    color: red;
    display: none;
    padding: 0;
    margin: 0;
    top: 4vh;

    /*make it so that it doesn't affect positioning of other elements*/
}