.rowHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100;
}

.vcontainer{
    height: 100vh;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    background-color: #282c34;
    color: white;
    font-size: calc(10px + 2vmin);
    max-width: 500px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2vh;
    padding: 2vh;
}

.infoContainer.centered {
    margin-top: 0;
}

.vcontainer.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
    overflow-y: auto;
}

.gridContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
    background-color: #282c34;
    padding-bottom: 4vh;
    color: white;
    font-size: calc(10px + 2vmin);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    max-width: 500px;
}

.gridItem {
    margin: 5px; /* Added margin for spacing between items */
    max-width: calc(50% - 10px); /* Adjusted max-width for responsiveness */
}


.bgcard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 2vh;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 2vh;
    margin-top: 7vh;
}

.ac {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.card button{
    background-color: #4CAF50;
    color: white;
    padding: 2.3vh 3.2vh;
    margin: 1.3vh 0;
    margin-top: 20%;
    border: none;
    border-radius: 0.65vh;
    cursor: pointer;
}

.sendButton {
    background-color: #4CAF50;
    color: white;
    padding: 2.3vh 3.2vw;
    margin: 1.3vh 0;
    margin-top: 10%;
    border: none;
    border-radius: 0.65vh;
    cursor: pointer;
}

#timer {
    font-size: 2.5em;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.smallInfo {
    font-size: 0.9rem;
    margin-top: -3vh;
    margin-bottom: 2vh;
}